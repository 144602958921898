import { useEffect, useState } from "react";
// import Popular from "./popular/page";
import { FaPlay } from "react-icons/fa";
import { url_game } from "./config";

function useMobilePlatform() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobilePlatforms = /android|iphone|ipod|windows phone|blackberry/i;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    setIsMobile(mobilePlatforms.test(userAgent) || isIOS);
  }, []);

  return isMobile;
}

export default function Home() {
  const isMobile = useMobilePlatform();

  const handleFullScreen = () => {
    const iframe = document.getElementById("game-iframe");
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen();
    } else if (iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    }
  };

  const handleClick = () => {
    window.location.href = "/game";
  };

  return (
    <div className="relative z-50 bg-[#E4FFF4]">
      {!isMobile ?
        <div className="2xl:flex lg:flex flex items-center justify-center 2xl:h-[85vh] lg:h-[75vh] h-[65vh] lg:mx-24 mx-8">
          <iframe
            id="game-iframe"
            src={url_game}
            width="100%"
            height="100%"
            frameBorder="0"
            title="Shady Bears"
            style={{
              aspectRatio: "16 / 9",
              objectFit: "cover",
              borderRadius: "8px",
              maxWidth: "fit-content",
              border: "2px solid #7DBCA1",
            }}
          ></iframe>
        </div>
        :
        <div className="flex flex-col items-center justify-center h-[35vh] lg:mx-24 mx-8 opacity-90 rounded-lg">
          <div className="relative w-full h-full flex flex-col items-center justify-center">
            <div className="absolute inset-0 bg-playnow opacity-50"></div>
            <button className="w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center relative animate-zoom" onClick={handleClick}>
              <FaPlay className="text-black w-6 h-6" />
            </button>
            <a className="poppins-bold font-bold text-[20px] text-white mt-3 relative"
              href="/game"
              style={{ textShadow: "1px 1px 2px black, -1px -1px 2px black, 1px -1px 2px black, -1px 1px 2px black" }}
            >Play now</a>
          </div>

        </div>
      }
      <div className="flex items-center justify-center">
        <div className="container">
          {!isMobile && <div className="2xl:flex lg:flex md:hidden hidden justify-center mt-6 2xl:mb-[40px] lg:mb-[28px] mb-[28px]">
            <button
              onClick={handleFullScreen}
              className="btn-full-screen 2xl:py-6 2xl:px-16 lg:py-3 lg:px-8 md:py-3 md:px-8 px-4 py-2 rounded"
            >
              <span className="lilita-one-regular 2xl:text-4xl lg:text-3xl md:text-3xl text-white">
                Full Screen
              </span>
            </button>
          </div>}
          {/* <Popular /> */}
          <div className='2xl:px-56 2xl:mt-14 2xl:pb-14 md:pb-14 md:mt-14 mt-10 lg:px-20 md:px-9 px-6 pb-10 bg-white'>
            <h1 className='2xl:text-[40px] md:text-[40px] text-[32px] lilita-one-regular text-[#7DBCA1] pt-8'>Shady Bears – Embark on an Adventure with Mysterious Bears</h1>
            <div className='2xl:mt-6 md:mt-6'>
              <span className='text-base flex !text-justify md:text-lg 2xl:text-lg lg:text-lg poppins-regular text-[#383838]'>
                Shady Bears is a new adventure and strategy game where you’ll step into a vibrant world filled with unexpected secrets. Transform into adorable yet powerful bears, gather resources, build your team, and conquer thrilling challenges to uncover the truth behind the enigmatic land of Shady.
              </span>
            </div>
          </div>
          <div className="text-[#383838] lg:px-20 md:px-9 px-6 2xl:pt-20 lg:pt-20 md:pt-14 content-des 2xl:px-56 2xl:pb-20 md:pb-14 pt-14 pb-14">
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Highlights of Shady Bears
            </h2>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-xl md:text-xl">
              1. Captivating Storyline
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              In Shady Bears, you’ll find yourself immersed in a mystical world where every bear has its own story and unique abilities. Each step of the journey unravels hidden secrets, testing your courage and strategic thinking.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-xl md:text-xl">
              2. Challenging Strategy Gameplay
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              Shady Bears is not just an adventure game; it requires you to carefully plan your strategies and harness the strengths of each bear in your lineup to triumph in battles.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-xl md:text-xl">
              3. Stunning Graphics
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              With crisp visuals, adorable characters, and vibrant environments, Shady Bears delivers a fantastic visual experience, making you feel like you’ve entered a magical animated world.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-xl text-xl md:text-xl">
              4. Endless Challenges
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              From simple tasks to complex levels, Shady Bears keeps you on your toes with increasingly difficult challenges. Patience, creativity, and strategy are essential to progress.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Why Play Shady Bears?
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              <ul
                style={{
                  listStyleType: "disc",
                  marginLeft: "30px",
                }}
              >
                <li>
                  Unique Gaming Experience: The blend of adventure, strategy, and mystery makes Shady Bears a must-play game.
                </li>
                <li>
                  Suitable for Everyone: Whether you’re a beginner or a seasoned strategy gamer, Shady Bears offers an experience tailored to all skill levels.
                </li>
                <li>
                  Regular Updates: New content, characters, and exciting events are consistently added to keep the excitement alive.
                </li>
                <li>
                  Play Anytime, Anywhere: Available on browsers and mobile devices, you can enjoy Shady Bears whenever and wherever you want.
                </li>
              </ul>
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Join the World of Shady Bears Today!
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular flex !text-justify">
              Don’t miss out on the chance to experience Shady Bears – a game that’s as entertaining as it is challenging. Explore the colorful world of bears, conquer thrilling challenges, and lead the way in uncovering the mysteries of Shady.
            </div>

            <div className="control flex items-center 2xl:mt-10 lg:mt-10 mt-4 md:mt-10">
              <div className="2xl:pl-8 2xl:py-8 lg:pl-8 lg:py-8 px-8 py-8">
                <h2 className="2xl:text-2xl lg:text-2xl md:text-2xl text-2xl font-bold text-start lilita-one-regular flex !text-justify">
                  How to Play Shady Bears
                </h2>
                <ul
                  style={{
                    listStyleType: "disc",
                    marginLeft: "30px",
                    marginTop: '10px'
                  }}
                >
                  <li>
                    Collect Resources: Explore the map and gather key items to upgrade your team and unlock new bears.
                  </li>
                  <li>
                    Build Your Team: Each bear in Shady Bears has unique skills. Choose the right combination to tackle different challenges.
                  </li>
                  <li>
                    Fight Smart: Use clever strategies and skills to defeat enemies, complete missions, and advance further.
                  </li>
                  <li>
                    Discover the Storyline: Continue your journey, overcome levels, and gradually unveil the secrets of the Shady land.
                  </li>
                </ul>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}
